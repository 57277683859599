export const TOKEN_NAME = 'accessToken';
export const REFRESH_TOKEN_NAME = 'refreshToken';
export const EVENT_ID = 'event_id';
export const ID = 'userId';

export const setEventId = event => {
  if (!event) localStorage.removeItem(EVENT_ID);
  localStorage.setItem(EVENT_ID, event);
};

export const getEventId = () => localStorage.getItem(EVENT_ID);

export const setToken = accessToken => {
  if (!accessToken) localStorage.removeItem(TOKEN_NAME);
  localStorage.setItem(TOKEN_NAME, accessToken);
};
export const setId = userId => {
  if (!userId) localStorage.removeItem(ID);
  localStorage.setItem(ID, userId);
}

export const getToken = () => localStorage.getItem(TOKEN_NAME);
export const getId = () => localStorage.getItem(ID);

export const setRefreshToken = refreshToken => {
  if (!refreshToken) localStorage.removeItem(REFRESH_TOKEN_NAME);
  localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);
};

export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_NAME);

export const isAuthenticated = () => Boolean(getToken());

export const signOut = () => {
  localStorage.removeItem(EVENT_ID);
  localStorage.removeItem(TOKEN_NAME);
  localStorage.removeItem(REFRESH_TOKEN_NAME);
  sessionStorage.clear();
};

export const validateStatus = status => status >= 200 && status < 300;
