import { FAST_INTERVIEW_RESET } from '@store/reducers/fast-interview';
import { USER_LOGIN_SUCCESS, USER_RESET } from '@store/reducers/user';
import { setEventId, setRefreshToken, setToken, signOut,setId } from '@utils/session-api';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';

export const userLogin = data => {
  return dispatch => {
    const { name, email, token, refreshToken, photoUrl, id } = data;

    const dataLayerArgs = {
      dataLayer: {
        event: 'userId',
        user_id: id,
      },
    };
    TagManager.dataLayer(dataLayerArgs);

    Sentry.setUser({ id, username: name, email });

    setEventId(id || '');
    setToken(token || '');
    setId(id || '');
    setRefreshToken(refreshToken || '');

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: { name, email, photoUrl },
    });
  };
};

export const userLogout = () => {
  return dispatch => {
    signOut();

    dispatch({
      type: USER_RESET,
    });

    dispatch({
      type: FAST_INTERVIEW_RESET,
    });
  };
};
