import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

import SnackbarProvider from '@components/snackbar/snackbar-provider';
import Router from './routes';
import ThemeProvider from '@layouts/theme';
import { persistor, store } from '@store/index';
import { useEffect, useState } from 'react';
import ModalNotConnected from '@components/modal-not-connected';

export default function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  

  

  useEffect(() => {
    const setOnline = () => setIsOnline(true);
    const setOffline = () => setIsOnline(false);

    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return (
    <div className='App'>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider>
              <SnackbarProvider>
                <ModalNotConnected opened={!isOnline} />
                <Router />
              </SnackbarProvider>
            </ThemeProvider>
          </LocalizationProvider>
        </PersistGate>
      </Provider>
    </div>
  );
}
